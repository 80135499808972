import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { Store } from 'typesafe-actions';
import initStore from './store';

import { GlobalStyle } from './styles/global';
import { theme } from './styles/theme';

import getWallet, { Wallet } from './utils/wallet';
import { setWallet } from './store/wallet/actions';


import FrontPage from './pages/index';
import FAQPage from './pages/faq';
import GamingPage from './pages/gaming';
import NotFoundPage from './pages/404';

interface Props {

}

interface State {
    address: string;
}

export default class App extends React.Component<Props, State> {
    private wallet: Wallet;
    private store: Store;
    constructor(props: Props) {
        super(props);

        this.state = {
            address: '',
        }

        this.store = initStore();
        this.wallet = getWallet();
        this.wallet.subscribe(this.handleWallet);
    }

    handleWallet = (address: string) => {
        this.store.dispatch(setWallet(address))
    }

    render() {

        return (
            <Provider store={this.store}>
                <ThemeProvider theme={theme} >
                    <GlobalStyle />
                    <Router>
                        <Switch>
                            <Route path="/faq" exact={true}>
                                <FAQPage />
                            </Route>
                            <Route path="/lottery" exact={true}>
                                <GamingPage />
                            </Route>
                            <Route path="/" exact={true}>
                                <FrontPage />
                            </Route>
                            <Route>
                                <NotFoundPage />
                            </Route>
                        </Switch>
                    </Router>
                </ThemeProvider>
            </Provider>
        );
    }
}