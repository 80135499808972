import { createAction, createAsyncAction } from 'typesafe-actions';
import { SignOwnershipProps, SignOwnershipResult } from '../../services/wallet';

export const signOwnership = createAsyncAction(
    'SIGN_OWNERSHIP_REQUEST',
    'SIGN_OWNERSHIP_SUCCESS',
    'SIGN_OWNERSHIP_FAILED'
)<SignOwnershipProps, SignOwnershipResult, string>();

export const connectWallet = createAsyncAction(
    'CONNECT_WALLET_REQUEST',
    'CONNECT_WALLET_SUCCESS',
    'CONNECT_WALLET_FAILED'
)<undefined, undefined, string>();

export const disconnectWallet = createAsyncAction(
    'DISCONNECT_WALLET_REQUEST',
    'DISCONNECT_WALLET_SUCCESS',
    'DISCONNECT_WALLET_FAILED'
)<undefined, undefined, string>();

export const setWallet = createAction('SET_WALLET')<string>();