import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from "@walletconnect/web3-provider";

export class Wallet {
    private onAddress?: (address: string) => void;
    private web3Modal: Web3Modal;
    private web3!: Web3;
    public address: string = '';

    constructor() {
        this.web3Modal = new Web3Modal({
            cacheProvider: true,
            providerOptions: this.getProviderOptions()
        });
    }

    private initWeb3 = (provider: any) => {
        const web3: Web3 = new Web3(provider);
        web3.eth.extend({
            methods: [
                {
                    name: "chainId",
                    call: "eth_chainId",
                    outputFormatter: web3.utils.hexToNumber as any
                }
            ]
        });
        return web3;
    }

    public getWeb3 = async () => {
        if (!this.web3) {
            await this.connect();
        }
        return Promise.resolve(this.web3 as any);
    }

    private getProviderOptions = () => {
        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    rpc: {
                        137: "https://rpc-mainnet.matic.network/",
                    },
                }
            }
        };
        return providerOptions;
    };

    public connect = async (): Promise<void> => {
        const provider = await this.web3Modal.connect();
        await this.subscribeProvider(provider);

        this.web3 = this.initWeb3(provider);
        const accounts = await this.web3.eth.getAccounts();
        const address = accounts[0];

        this.address = address;
        if (this.onAddress) {
            this.onAddress(address);
        }
        return Promise.resolve();
    }

    public disconnect = async () => {
        this.web3Modal.clearCachedProvider();
        this.address = '';
        if (this.onAddress) {
            this.onAddress('');
        }
    }

    public subscribe = (onAddress: (address: string) => void) => {
        this.onAddress = onAddress;
        if (this.web3Modal.cachedProvider) {
            this.connect();
        }
    }

    private subscribeProvider = async (provider: any) => {
        if (!provider.on) return;

        provider.on('accountsChanged', async (accounts: string[]) => {
            const address = accounts[0];
            this.address = address;
            this.onAddress && this.onAddress(address);
            !address && this.web3Modal.clearCachedProvider();
        });

        provider.on('disconnect', (code: number, reason: string) => {
            this.web3Modal.clearCachedProvider();
            this.address = '';
            this.onAddress && this.onAddress('');
        });

        provider.on('close', () => {
            this.web3Modal.clearCachedProvider();
            this.address = '';
            this.onAddress && this.onAddress('');
        });
    };
}

let instance: Wallet;

const getWallet = (): Wallet => {
    if (instance) {
        return instance;
    } else {
        instance = new Wallet();
        return instance;
    }
}

export default getWallet;