import React from 'react';
import styled from 'styled-components';
import Footer from './footer';

import Header from './header';
import Navigation from './navigation';

interface LayoutProps {
    enabled: boolean;
}

const LayoutContainer = styled.div<LayoutProps>`
    position: relative;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.offWhite};
    display: flex;
    flex-direction: column;
    color: white;
`;

const Content = styled.div<LayoutProps>`
    margin-top: ${({ theme }) => theme.layout.headerHeight};
    flex: 1;
    display: flex;
    flex-direction: column;
`;

interface Props {
    children: React.ReactNode;
    noFooter?: boolean;
    stickyHeader?: boolean;
}

interface State {
    hasScrolled: boolean;
    showNavigation: boolean;
}

export default class Layout extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            hasScrolled: false,
            showNavigation: false,
        }
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const scroll = window.scrollY;
        if (scroll > 25) {
            this.setHasScrolled(true);
        } else {
            this.setHasScrolled(false);
        }
    }

    setHasScrolled = (newValue: boolean) => {
        const { hasScrolled } = this.state;
        if (hasScrolled != newValue) {
            this.setState({ hasScrolled: newValue });
        }
    }

    handleNavigation = () => {
        this.setState(
            prevState => ({
                showNavigation: !prevState.showNavigation
            })
            , () => {
                if (this.state.showNavigation) {
                    document.body.style.overflowY = 'hidden';
                } else {
                    document.body.style.overflowY = 'scroll';
                }
            }
        )
    }

    render() {

        const { children, noFooter } = this.props;
        const { hasScrolled, showNavigation } = this.state;

        return (
            <LayoutContainer enabled={showNavigation}>
                <Navigation
                    enabled={showNavigation}
                    handleClose={this.handleNavigation}
                />
                <Header
                    stickyHeader={!!this.props.stickyHeader}
                    enabled={showNavigation}
                    hasScrolled={hasScrolled}
                    handleNavigation={this.handleNavigation}
                />
                <Content enabled={showNavigation}>
                    {children}
                </Content>
                {
                    !noFooter && <Footer />
                }
            </LayoutContainer>
        )
    }
}