import { SignOwnershipResult } from "../../services/wallet";

export interface WalletState {
    account: string;
    signingPending: boolean;
    signedOwnership?: SignOwnershipResult;
}

export const initialState: WalletState = {
    account: '',
    signingPending: false,
}