import React from "react";
import styled from 'styled-components';

export const Box = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.offWhite};
  width: 100%;
  @media (max-width: 1000px) {
    padding: 1rem
  }
`;

export const CopyRight = styled.span`
    color: white;
    display: flex;
    justify-content: center;
    padding-top: 1rem;

    & > * {
        margin: 0 0.25rem;
    }

    ${({ theme }) => theme.media.small} {
        margin: 1.5rem;
        & > * {
            margin: 0 0.2rem;
        }
    }

`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const Heading = styled.div`
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    font-size: 1.2rem;
    color: #fff;
    font-weight: 500;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        background-color: #ffffff40;
    }

    &:active {
        transform: translateY(2px);
    }
`;

const Icon = styled.img`
    height: 1.2rem;
    width: 1.2rem;
`;

const MailIcon = styled.img`
    height: 1.2rem;
    width: 1.7rem;
`;

export const Linkage = styled.a`
    line-height: 0.5rem;
    margin-top: 0.1rem;
`;

const Footer = () => {
    return (
        <Box>
            <Container>
{/*                 <Column>
                    <a href='/terms-and-conditions'><Heading>Terms & Conditions</Heading></a>
                </Column> */}
                <Column>
                    <a href='/disclaimer.pdf' target='_blank'><Heading>Disclaimer</Heading></a>
                </Column>
{/*                 <Column>
                    <a href='/privacy-policy'><Heading>Privacy Policy</Heading></a>
                </Column> */}
            </Container>
            <CopyRight>
                {/* <span>© Tony.finance 2021</span> */}
{/*                 <span>-</span>
                <Linkage href='mailto:contact@polyhunter.io'>
                    <MailIcon src="/mailwhite.png" />
                </Linkage>
                <span>-</span>
                <Linkage href='https://twitter.com/polyhunter_io' target="_blank" rel="noreferrer"><Icon src="/twitterwhite.png" />
                </Linkage>
                <span>-</span>
                <Linkage href='https://t.me/PolyHunter' target="_blank" rel="noreferrer"><Icon src="/telegramwhite.png" />
                </Linkage> */}
                </CopyRight>
        </Box>
    );
};
export default Footer;