import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';

import ShowMore from '../ui/animated/show-more';

const ZeroContainer = styled.div`
    ${({ theme }) => theme.layout.desktopWidth};
    width: 100%;
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "Content Image"
        "Extra Nothing";
    column-gap: 3rem;
    padding-top: 4rem;

    ${({ theme }) => theme.media.small} {
        grid-template-columns: 1fr;
        grid-template-areas:
            "Content"
            "Extra"
            "Image";
        gap: 0rem;
    }
`;

const ZeroMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    ${({ theme }) => theme.media.small} {
        align-items: center;
    }
`;

interface ReadMoreProps {
    active: boolean;
}

const ReadMoreContainer = styled(ShowMore) <ReadMoreProps>`
    grid-area: Extra;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-size: 1.5rem;
`;

const ROI = styled.img.attrs({
    src: '/images/money.svg'
})`
    width: 80%;
`;

const FAQPageContainer = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
   
`;

const FAQHero = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.offWhite};
`;

const FAQHeroContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    ${({ theme }) => theme.media.small} {
        grid-template-columns: 1fr;
        gap: 0rem;
    }
    padding: 5rem 0;
    margin-left: 1rem;
    margin-right: 1rem;
`;

const FAQContainer = styled.div`
    padding: 1rem 2rem 5rem 2rem;
    background-color: ${({ theme }) => theme.colors.offWhite};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    border-top: 2px solid black;
    border-bottom: 2px solid black;

    ${({ theme }) => theme.media.small} {
        padding: 8rem 2rem;
    }
`;

const QuestionsContainer = styled.div`
    ${({ theme }) => theme.layout.desktopWidth};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50rem;
    max-width: 100%;
`;

const QuestionParagraph = styled.p`
    font-size: 1.35rem;
    font-weight: 400;
    padding-bottom: 1.5rem;
    color: white;
    & a {
        &:link, &:visited {
            color: #00b0ff;
        }
        &:active {
        color:#7476b4;
        text-decoration: underline;
        }
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.7rem;
    margin-left: 8rem;
    ${({ theme }) => theme.media.mediumDown} {
        margin-left: 0rem;
    }
`;

const PageTitle = styled.h1`
    margin-bottom: 0.5rem;
    color: white;
`;

const FAQPageTitle = styled.h1`
    font-size: 3rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    color: white;
`;


const SubTitle = styled.h3`
    margin: 0;
    color: white;
    
    
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    margin-left: 12rem;
    overflow: hidden;
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    border: 3px solid white;
    background-color: #FA1270;
    position: relative;
    ${({ theme }) => theme.media.mediumDown} {
        margin-left: 0rem;
    }
`;

const Logo = styled.img.attrs({
    src: '/zillainu.png'
})`

width: 25rem;
margin-top: 2rem;
    
`;

const ButtonsContainer = styled.div`
    grid-area: Buttons;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 2rem 0;

    ${({ theme }) => theme.media.small} {
        justify-content: center;
    }
`;

const NavButton = styled.span`
    margin: 0 0.25rem;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;
    border: 2px solid #FFD384;
    color: ${({ theme }) => theme.colors.anotherFont};

    &:hover {
        background-color: #ffffff40;
    }

    &:active {
        transform: translateY(2px);
    }

    transition: all 0.2s ${({ theme }) => theme.transitions.main};
`;


const fadeInRight = keyframes`
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
`;

const closeFade = keyframes`
    0% {
        opacity : 0;
    }
    100% {
        opacity: 1;
    }
`;


const CloseNav = styled.h1`
    display: block;
    position: fixed;
    top: 2%;
    right: 6%;
    cursor: pointer;
    color: white;
    opacity : 0;
    animation: ${closeFade} .1s ease forwards;
    animation-delay: .6s;
`;

const NavBar = styled.nav`
    position: relative;
    height: 70%;
    top: 35%;
    transform: translateY(-50%);
    font-weight: 400;
    text-align: center;
`;

const UnorderedList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
`;

interface RoadProps {
    enabled: boolean;
    colored?: boolean;
}

const Hyper = styled.div`

font-size: 1.5rem;
margin-top: 1rem;
margin-bottom: 1rem;
color: white;
padding: 1rem;

${({ theme }) => theme.media.small} {
font-size: 1rem;
padding-top: 0.4rem;
width: 100%;
text-align: center;

}
`

const ListItem = styled.li<RoadProps>`
    text-align: left;
    display:  flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 2rem;
    opacity: 0;
    color: ${({ colored }) => colored ? '#f7c86f' : 'white'};
    font-size: 1.3rem;
    animation: ${({ enabled }) => enabled ? css`${fadeInRight} .5s ease forwards` : 'none'};
    animation-delay: .6s;
    :nth-of-type(2) {
        animation-delay: .8s;
      }
    
    :nth-of-type(3) {
        animation-delay: 1s;
      }
    
    :nth-of-type(4) {
    animation-delay: 1.2s;
    }
`;



const ListItem2 = styled.li<RoadProps>`
    text-align: left;
    display:  flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 2rem;
    font-size: 1.3rem;
    opacity: 0;
    animation: ${({ enabled }) => enabled ? css`${fadeInRight} .5s ease forwards` : 'none'};
    animation-delay: 1.4s;
    
    :nth-of-type(1) {
        
        animation-delay: 1.6s;
      }
    

    :nth-of-type(2) {
        animation-delay: 1.6s;
      }
    
    :nth-of-type(3) {
        animation-delay: 1.8s;
      }
    
    :nth-of-type(4) {
    animation-delay: 2s;
    }
`;

const ListItem3 = styled.li<RoadProps>`
    text-align: left;
    display:  flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 2rem;
    font-size: 1.3rem;
    opacity: 0;
    animation: ${({ enabled }) => enabled ? css`${fadeInRight} .5s ease forwards` : 'none'};
    animation-delay: 2.2s;
    

    :nth-of-type(2) {
        animation-delay: 2.4s;
      }
    
    :nth-of-type(3) {
        animation-delay: 2.6s;
      }
    
    :nth-of-type(4) {
    animation-delay: 2.8s;
    }
`;

const RoadMapGrid = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    ${({ theme }) => theme.media.small} {
        grid-template-columns: 1fr;
    }
    
`;

const PawLogo = styled.img`
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
    margin-left: 1rem;
    ${({ theme }) => theme.media.small} {
      width: 2.1rem;
      height: 2.1rem;
  }
`;

const Heading = styled.h2`
    margin-bottom: 0.5rem;
    align-self: center;
    
`;

const FAQHeroContainer2 = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    color: white;
    ${({ theme }) => theme.media.small} {
        flex-direction: column;
        padding-top: 0rem;
    }
`;


interface Props {

}

interface State {
    roadmap: boolean;
}

export default class FAQPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            roadmap: false
        }
    }

    private roadmapRef = React.createRef<HTMLDivElement>();
    private observer!: IntersectionObserver;

    componentDidMount = () => {

        this.setState({ roadmap: false });

        this.observer = new IntersectionObserver(
            (entries) => {
                if (this.roadmapRef.current && entries[0].isIntersecting) {
                    setTimeout(() => {
                        this.setState({ roadmap: true });
                    }, 300);
                }
            },
        );
        if (this.roadmapRef.current) {
            this.observer.observe(this.roadmapRef.current);
        }
    }

    componentWillUnmount = () => {
        if (this.roadmapRef.current) {
            this.observer.unobserve(this.roadmapRef.current);
        }
    }

    render() {
        return (
            <FAQHero id="roadmap" ref={this.roadmapRef}>
                <FAQHeroContainer2>
                    <FAQPageTitle>Roadmap</FAQPageTitle>
                    <RoadMapGrid>
                        <NavBar>
                            <Hyper>Phase 1</Hyper>
                            <UnorderedList>
                                <ListItem enabled={this.state.roadmap} colored
                                >

                                    <PawLogo src="/images/zilla-coin.png"></PawLogo>


                                    Marketing push

                                </ListItem>
                                <ListItem enabled={this.state.roadmap} colored
                                >
                                    <PawLogo src="/images/zilla-coin.png"></PawLogo>

                                    Website opened
                                </ListItem>
                                <ListItem enabled={this.state.roadmap}
                                >

                                    <PawLogo src="/images/zilla-coin.png"></PawLogo>
                                    Lottery launched

                                </ListItem>
                                <ListItem enabled={this.state.roadmap}
                                >

                                    <PawLogo src="/images/zilla-coin.png"></PawLogo>
                                    1000 telegram members

                                </ListItem>
                            </UnorderedList>
                        </NavBar>
                        <NavBar>
                            <Hyper>Phase 2</Hyper>
                            <UnorderedList>
                                <ListItem2 enabled={this.state.roadmap}
                                >
                                    <PawLogo src="/images/zilla-coin.png"></PawLogo>
                                    Influencer marketing push

                                </ListItem2>
                                <ListItem2 enabled={this.state.roadmap}
                                >
                                    <PawLogo src="/images/zilla-coin.png"></PawLogo>

                                    Listing on CoinGecko

                                </ListItem2>
                                <ListItem2 enabled={this.state.roadmap}
                                >
                                    <PawLogo src="/images/zilla-coin.png"></PawLogo>

                                    Listing on CoinMarketCap

                                </ListItem2>
                                <ListItem2 enabled={this.state.roadmap}
                                >
                                    <PawLogo src="/images/zilla-coin.png"></PawLogo>

                                    Third party audit

                                </ListItem2>
                            </UnorderedList>
                        </NavBar>
                        <NavBar>
                            <Hyper>Phase 3</Hyper>
                            <UnorderedList>
                                <ListItem3 enabled={this.state.roadmap}
                                >
                                    <PawLogo src="/images/zilla-coin.png"></PawLogo>
                                    Meme community contests

                                </ListItem3>
                                <ListItem3 enabled={this.state.roadmap}
                                >
                                    <PawLogo src="/images/zilla-coin.png"></PawLogo>

                                    Website redesign

                                </ListItem3>
                                <ListItem3 enabled={this.state.roadmap}
                                >
                                    <PawLogo src="/images/zilla-coin.png"></PawLogo>

                                    Staking platform

                                </ListItem3>
                                <ListItem3 enabled={this.state.roadmap}
                                >
                                    <PawLogo src="/images/zilla-coin.png"></PawLogo>

                                    Marketing partnerships

                                </ListItem3>
                            </UnorderedList>
                        </NavBar>
                    </RoadMapGrid>

                </FAQHeroContainer2>
            </FAQHero>
        );
    }
}