import React from 'react';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';
import ERC20TokenABI from '../constants/contracts/bsc/ERC20ABI';
import lotteryABI from '../constants/contracts/bsc/lotteryABI';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ethers } from 'ethers';

import Layout from '../components/layout/layout';
import { RootState } from 'typesafe-actions';
import getWallet from '../utils/wallet';

import { connectWallet, disconnectWallet } from '../store/wallet/actions';

const lotteryAddress = '0xF7fd6582Ebf70bbba03c869841C4eceb117120A4';
const tokenAddress = '0xE26F748DDDFC8347B80a4cCAad197841837fB427';

const BackgroundContainer = styled.div`
    background-color: ${({ theme }) => theme.media.offWhite};
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const GamingPageContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
    padding: 1rem;
    background: url("/images/wave-top.svg");;
    background-position:0% 100%;
    background-repeat:no-repeat;
    background-size: contain;
`;


const ContentContainer = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 2rem;

    & h2 {
        margin-left: 3rem;
    }
`;

const Logo = styled.img`
  width: 10rem;
`;

const Cock = styled.img`
  width: calc(6vmin);
  margin: 0 auto;
  margin-left: 1vmin;
`;

const SubTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & h2 {
    margin: 0;
  }
`;

const ConnectButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.25rem;
    padding: 1rem;
    border-radius: 2rem;
    font-size: 1.6rem;
    font-weight: 700;
    cursor: pointer;
    border: 2px solid #ffffff95;

    &:hover {
        background-color: #ffffff40;
    }

    &:active {
        transform: translateY(2px);
    }

    transition: all 0.2s ${({ theme }) => theme.transitions.main};
`;

const ClaimButton = styled(ConnectButton)`

`;

const AllInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  & h4 {
      margin: 0.5rem;
      margin-right: 1rem;
      font-weight: 400;
  }

  & span {
      align-text: right;
  }
`;

const LotteryContainer = styled.div`
  width: 40rem;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h4 {
      font-size: 1.6rem;
  }
`;

const NumberInput = styled.input.attrs({ type: 'number' })`
  background-color: #fff;
  padding: 1rem;
  font-size: 1rem;
  outline: none;
  border: 2px solid ${({ theme }) => theme.colors.tonyDarkBlue};
`;

const CTAContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 2rem;
  border: 2px solid ${({ theme }) => theme.colors.tonyDarkBlue};;
  margin-top: 1rem;
  color: black;
  font-size: 2rem;

  & span {
      padding: 2rem;
  }
`;

const dispatchProps = {
    connectWallet: connectWallet.request
};

type Props = {

} & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

interface State {
    connectedAccount: string;
    connected: boolean;
    currentPotSize: number;
    numberOfWinners: number;
    totalPayout: number;
    ticketsSold: number;
    hasApproved: boolean;
    luckyNumber: number;
    didPlay: boolean;
    win: number;
    price: number;
    playing: boolean;
    approving: boolean;
    currentNumTickets: number;
}

class GamingPage extends React.Component<Props, State> {
    private web3!: Web3;
    private lotteryContract!: Contract;
    private tokenContract!: Contract;

    constructor(props: Props) {
        super(props);

        this.state = {
            approving: false,
            currentNumTickets: 100,
            hasApproved: false,
            connectedAccount: '',
            connected: false,
            currentPotSize: 0,
            numberOfWinners: 0,
            totalPayout: 0,
            ticketsSold: 0,
            price: 0,
            luckyNumber: 1,
            win: 0,
            didPlay: false,
            playing: false,
        }
    }

    componentDidMount = async () => {
        this.web3 = await getWallet().getWeb3();
        this.lotteryContract = new this.web3.eth.Contract(lotteryABI, lotteryAddress);
        this.tokenContract = new this.web3.eth.Contract(ERC20TokenABI, tokenAddress);
        this.updateStats();
    }

    componentDidUpdate = async (prevProps: Props) => {
        if (!prevProps.connectedWallet && !!this.props.connectedWallet) {
            this.checkApproved();
        } else if (!!prevProps.connectedWallet && !this.props.connectedWallet) {
            this.setState({
                hasApproved: false
            });
        }
    }

    updateStats = async () => {
        const currentNumTickets = await this.lotteryContract.methods.NumberOfTickets().call();
        const currentPotSize = await this.lotteryContract.methods.CurrentPotSize().call();
        const numberOfWinners = await this.lotteryContract.methods.NumberOfWinners().call();
        const totalPayout = await this.lotteryContract.methods.TotalPayout().call();
        const ticketsSold = await this.lotteryContract.methods.TicketsSold().call();
        const price = await this.lotteryContract.methods.Price().call();
        let win = 0;
        if (!!this.props.connectedWallet) {
            win = await this.lotteryContract.methods.getWinningsForAddress(this.props.connectedWallet).call();
            this.checkApproved();
        }

        this.setState({
            currentNumTickets,
            currentPotSize,
            numberOfWinners,
            totalPayout,
            ticketsSold,
            price,
            win
        });
    }

    handleApprove = async () => {
        this.setState({
            approving: true,
        });
        const { connectedWallet } = this.props;
        const approved = await this.tokenContract.methods.approve(lotteryAddress, ethers.constants.MaxUint256).send({ from: connectedWallet });
        this.setState({ approving: false, hasApproved: approved });
    }

    play = async () => {
        const { connectedWallet } = this.props;
        const { connectedAccount, luckyNumber } = this.state;
        this.setState({
            playing: true,
        });
        try {
            const a = await this.lotteryContract.methods.play(luckyNumber).send({ from: connectedWallet });
            await new Promise(resolve => setTimeout(resolve, 3000));
            this.setState({
                playing: false,
                didPlay: true,
            })
        } catch (error) {
            this.setState({ playing: false });
        }

    }

    handleClaim = async () => {
        const { connectedWallet } = this.props;
        const a = await this.lotteryContract.methods.claim().send({ from: connectedWallet });
        this.updateStats();
    }

    checkApproved = async () => {
        if (!this.props.connectedWallet) {
            return;
        }
        if (this.tokenContract) {
            const allowance = await this.tokenContract.methods.allowance(this.props.connectedWallet, lotteryAddress).call();
            const hasApproved = allowance > 100000;
            this.setState({ hasApproved });
        }
    }
    
    handleConnect = async () => {
        return this.props.connectWallet();
    }

    handleClick = async () => {
        const { hasApproved, connectedAccount } = this.state;
        const { connectedWallet } = this.props;
        if (!connectedWallet) {
            return this.handleConnect();
        } else if (!hasApproved) {
            await this.handleApprove();
        } else {
            await this.play();
        }
        this.updateStats();
    }

    getButton = () => {
        const { hasApproved, } = this.state;
        const { connectedWallet } = this.props;
        if (!connectedWallet) {
            return 'Connect wallet';
        } else if (!hasApproved) {
            return 'Approve';
        } else {
            return 'Buy';
        }
    }

    onLuckyNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const luckyNumber = parseInt(event.currentTarget.value);
        if (luckyNumber < 1 || luckyNumber > this.state.currentNumTickets) {
            return;
        } else {
            this.setState({ luckyNumber });
        }
    }

    getMessage = () => {
        if (!this.props.connectedWallet) {
            return 'Good luck!';
        } else if (!this.state.hasApproved) {
            return 'Good luck!';
        }
        return `Choose a lucky number between 1 and ${this.state.currentNumTickets}`;
    }

    getResultMessage = () => {
        const { connectedAccount, approving, didPlay, win, price, playing, hasApproved, luckyNumber, } = this.state;
        const { connectedWallet } = this.props;
        if (!connectedWallet) {
            return 'Connect wallet on BSC to start';
        }
        if (approving) {
            return 'Approving...';
        }
        if (!hasApproved) {
            return 'Approve ZillaInu to enable playing';
        }
        if (playing) {
            return `Drawing...`;
        }
        if (win > 0) {
            return `Contratulations! You won ${win / 10 ** 9}!`;
        } else if (didPlay) {
            return 'No win! Try again!';
        } else {
            return `Click to play for ${price} ZillaInu`;
        }
    }

    render() {
        const {
            connected,
            currentNumTickets,
            currentPotSize,
            hasApproved,
            numberOfWinners,
            totalPayout,
            ticketsSold,
            luckyNumber,
            win,
        } = this.state;
        return (
            <Layout stickyHeader>
                <BackgroundContainer>
                    <GamingPageContainer>
                        <ContentContainer>
                            <HeaderContainer>

                            </HeaderContainer>
                            <LotteryContainer>

                                <AllInfo>
                                    <Info>
                                        <h4>🏆 Current pot: </h4>
                                        <span>{(currentPotSize / 10 ** 9).toFixed(0)}</span>
                                    </Info>
                                    <Info>
                                        <h4>💰 Total Payout: </h4>
                                        <span>{(totalPayout / 10 ** 9).toFixed(0)}</span>
                                    </Info>
                                    <Info>
                                        <h4>🥇 Winners: </h4>
                                        <span>{numberOfWinners}</span>
                                    </Info>
                                    <Info>
                                        <h4>🎫 Tickets Sold:</h4>
                                        <span>{ticketsSold}</span>
                                    </Info>
                                </AllInfo>
                                {/* <h5>{`Choose a lucky number between 1 and ${currentNumTickets}`}</h5> */}
                                <h4>{this.getMessage()}</h4>
                                <ResultContainer>
                                    <span>
                                        {this.getResultMessage()}
                                    </span>
                                    {
                                        win > 0 && (
                                            <ClaimButton onClick={this.handleClaim}>
                                                Claim winnings
                                            </ClaimButton>
                                        )
                                    }
                                </ResultContainer>
                                <CTAContainer>
                                    <div>
                                        {hasApproved && (
                                            <NumberInput
                                                placeholder='number'
                                                value={luckyNumber}
                                                onChange={this.onLuckyNumberChange}
                                            />
                                        )}
                                    </div>
                                    <ConnectButton onClick={this.handleClick}>
                                        {this.getButton()}
                                    </ConnectButton>
                                </CTAContainer>
                            </LotteryContainer>
                        </ContentContainer>
                    </GamingPageContainer>
                </BackgroundContainer>
            </Layout>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    connectedWallet: state.wallet.account
});

export default connect(
    mapStateToProps,
    dispatchProps
)(GamingPage);