import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Layout from '../components/layout/layout';
import { RootState } from 'typesafe-actions';

import Hero from '../components/frontpage/hero';
import Problem from '../components/frontpage/problem';
import Zero from '../components/frontpage/zero';
import Solution from '../components/frontpage/solution';
import Roadmap from '../components/frontpage/roadmap';
import ContactUs from '../components/frontpage/contact-us';

const FrontPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
`;

const LoaderContainer = styled.div`
`;

const dispatchProps = {

};

type Props = {

} & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

interface State {
    enabled: boolean;
    isLoading: boolean;
}

class FrontPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            enabled: false,
            isLoading: true,
        }
    }

    componentDidMount = async () => {

    }

    render() {

        return (
            <Layout>
                <FrontPageContainer>
                    <Hero />
                    <Problem />
                    <Zero /> 
                </FrontPageContainer>
            </Layout>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {

    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(FrontPage);