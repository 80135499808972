export interface Theme {
    fonts: {
        [key: string]: string;
    };
    colors: {
        [key: string]: string;
    };
    layout: {
        [key: string]: string;
    }
    media: {
        [key: string]: string;
    };
    transitions: {
        [key: string]: string;
    }
    ui: {
        icons: {
            sizes: {
                [key: string]: string;
            };
        };
    };
    z: {
        [key: string]: number;
    }
}

const breakpoints = {
    extrasmall: '660px',
    small: '960px',
    medium: '1280px',
    large: '1440px',
}


// Theme used in ThemeProvider @ _app.tsx
export const theme: Theme = {
    fonts: {
        main: "futura-pt, sans-serif",
    },
    colors: {
        offWhite: '#7bb078',
        basicBlue: '#3282B8',
        borderGray: '#E2E2E2',
        backgroundGray: '#f2f2f2',
        darkPurple: '#1e1e1e',
        darkerPurple: '#190028',
        frenchViolet: '#7b2cbf',
        gunmetal: '#3a3047',
        headerBackground: '#6D8CC5',
        jet: '#220135',
        blackOlive: '#161316',
        minionYellow: '#EAD94C',
        pureWhite: 'white',
        blue: '#121212',
        lightBlue: '#1e1e1e',
        lighterBlue: '#0E2F58',
        justBeauty: '#89D1FE',
        lightPolyPurple: '#bb86fc',
        polyPurple: '#8247e5',
        purpleBackground: '#1e1823',
        purpleForeground: '#2b2732',
        lightPurpleForeground: '#3b3645',
        green: '#60C197',
        red: '#FF7886',
        tonyDarkBlue: '#4E814B',
    },
    layout: {
        desktopWidth: `
            max-width: 1400px;
            margin: 0 auto;
        `,
        gameWidth: '60rem',
        hudHeight: '5rem',
        headerHeight: '8rem',
        headerWidth: '78rem',
        footerHeight: '6rem',
        footerWidth: '78rem',
    },
    ui: {
        icons: {
            sizes: {
                small: '40px',
                medium: '60px',
                large: '100px',
            },
        },
    },
    media: {
        extrasmall: `@media (max-width: ${breakpoints.extrasmall})`,
        extrasmallUp: `@media (min-width: ${breakpoints.extrasmall})`,
        small: `@media (max-width: ${breakpoints.small})`,
        smallUp: `@media (min-width: ${breakpoints.small})`,
        mediumDown: `@media (max-width: ${breakpoints.medium})`,
        mediumUp: `@media (min-width: ${breakpoints.medium})`,
        largeDown: `@media (max-width: ${breakpoints.large})`,
        largeUp: `@media (min-width: ${breakpoints.large})`,
    },
    transitions: {
        main: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    z: {
        hidden: 0,
        bottom: 1,
        middle: 2,
        top: 3,
        override: 4,
    }
}