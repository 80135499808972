import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout/layout';
import Question from '../components/question';

const FAQPageContainer = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
`;

const FAQHero = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.offWhite};
`;

const FAQHeroContainer = styled.div`
    ${({ theme }) => theme.layout.desktopWidth};
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
    justify-items: center;
    ${({ theme }) => theme.media.small} {
        grid-template-columns: 1fr;
        gap: 0rem;
    }
`;

const FAQContainer = styled.div`
    padding: 15rem 2rem;
    background: url("/images/wave-bottom.svg"), url("/images/wave-top.svg");;
    background-position: 0% 0%, 0% 100%;
    background-repeat: no-repeat, no-repeat;
    background-size: contain, contain;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    ${({ theme }) => theme.media.small} {
        padding: 8rem 2rem;
    }
`;

const QuestionsContainer = styled.div`
    ${({ theme }) => theme.layout.desktopWidth};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50rem;
    max-width: 100%;
`;

const QuestionParagraph = styled.p`
    font-size: 1.2rem;
    font-weight: 400;
    padding-bottom: 1.5rem;
    & a {
        &:link, &:visited {
            color: black;
        }
        &:active {
        color: black;
        text-decoration: underline;
        }
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.7rem;
    color: white; //${({ theme }) => theme.colors.tonyDarkBlue};
`;

const PageTitle = styled.h1`
    margin-bottom: 0.5rem;
`;

const SubTitle = styled.h3`
    margin: 0;
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    //overflow: hidden;
    width: 23rem;
    height: 26rem;
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
    padding-top: 2.5rem;
`;

const TopCircle = styled.div`
    margin-top: 2.5rem;
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 40%;
    //background-color: white;
`;

const BottomCircle = styled.div`

`;

const Logo = styled.img.attrs({
    src: '/images/zilla-naked.png'
})`
    position: absolute;
    top: -2.5rem;
    left: 0rem;
    width: 25rem;
`;

interface Props {

}

interface State {

}

export default class FAQPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (
            <Layout>
                <FAQPageContainer>
                    <FAQHero>
                        <FAQHeroContainer>
                            <TitleContainer>
                                <PageTitle>Zilla Inu F.A.Q</PageTitle>
                                <SubTitle>Good read before investing</SubTitle>
                            </TitleContainer>
                            <LogoContainer>
                                <TopCircle>
                                    <Logo />
                                    <BottomCircle />
                                </TopCircle>
                            </LogoContainer>
                        </FAQHeroContainer>
                    </FAQHero>
                    <FAQContainer>
                        <QuestionsContainer>
                            <Question title={'Detailed tokenomics'} >
                                <QuestionParagraph>
                                    The token implements a 5% tax.
                                    <ul>
                                        <li>2% marketing. Our community has the control over this wallet, goes straight into building momentum.</li>
                                        <li>1% goes straight into the Liquidity Pool ensuring longevity of the coin.</li>
                                        <li>2% - Development fee</li>
                                    </ul>
                                </QuestionParagraph>
                            </Question>
                            <Question title={'LP lock'} >
                                <QuestionParagraph>
                                    We will lock the LP for 6 months using Team Finance. Link will come here. {/* <span><a href='https://deeplock.io/lock/0xe970AB7D282B43B7673fd41756B183bF4B15Af67' target="_blank">here.</a></span> */}
                                </QuestionParagraph>

                                
                            </Question>
                            <Question title={'How do I buy?'} >
                                <QuestionParagraph>
                                    The buy token links to Uniswap where you can buy the token with your Metamask or other browser wallets.
                                </QuestionParagraph>
                            </Question>
                        </QuestionsContainer>
                    </FAQContainer>
                </FAQPageContainer>
            </Layout>
        );
    }
}