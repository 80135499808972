import React from 'react';
import styled, { keyframes } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { connectWallet, disconnectWallet } from '../../store/wallet/actions';
import { RootState } from 'typesafe-actions';

interface NavProps {
    enabled: boolean;
    message?: string;
}

const fadeInRight = keyframes`
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
`;

const closeFade = keyframes`
    0% {
        opacity : 0;
    }
    100% {
        opacity: 1;
    }
`;


const NavigationContainer = styled.div<NavProps>`
    display: block;
    width: 100%;
    height: 100vh;
    max-width: ${({ enabled }) => enabled ? '100%' : '0%'};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    background-color: ${({ theme }) => theme.colors.offWhite};
    transition: all .6s ease;
`;

const CloseNav = styled.h1<NavProps>`
    display: ${({ enabled }) => enabled ? 'block' : 'none'};
    position: fixed;
    top: 2%;
    right: 6%;
    cursor: pointer;
    color: white;
    opacity : 0;
    animation: ${closeFade} .1s ease forwards;
    animation-delay: .6s;
`;

const NavBar = styled.nav`
    position: relative;
    height: 70%;
    top: 60%;
    transform: translateY(-50%);
    font-size: 34px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    text-align: center;
    z-index: 100;
`;

const UnorderedList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%; 
`;

const ListItem = styled.li<NavProps>`
    display:  ${({ enabled }) => enabled ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    opacity: 0;
    animation: ${fadeInRight} .5s ease forwards;
    animation-delay: .6s;
    cursor: pointer;
    position: relative;
    & >span {
        font-size: 0.7rem;
        font-variation-settings: 'wght' 900;
        border-radius: 0.75rem;
        display: flex;
        align-items: center;
        z-index: 99;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.brandColor1};
        color: ${({ theme }) => theme.colors.uiColor2} !important;
        bottom: 1.5rem;
        opacity: 0;
        color: white;
        text-decoration: none;
        white-space: nowrap;
        transition: all 0.15s ${({ theme }) => theme.transitions.main};
        &:after {
            content: '${({ message }) => message}';
        }
        pointer-events: none;
        text-transform: uppercase;
        padding: 0.5rem 0.75rem;
        top: -3rem;
    }

    &:hover {
        & >span {
            opacity: 1;
            transition: all 0.15s ${({ theme }) => theme.transitions.main};
            transition-delay: 0;
        }
    }

    :nth-of-type(2) {
        animation-delay: .8s;
      }
    
    :nth-of-type(3) {
        animation-delay: 1s;
      }
    
    :nth-of-type(4) {
    animation-delay: 1.2s;
    }
`;


interface Props {
    enabled: boolean;
    handleClose: () => void;
}

const Navigation: React.FC<Props> = ({ enabled, handleClose }) => {

    const address = useSelector((state: RootState) => state.wallet.account);

    const getAccountString = (account: string) => {
        const first = account.slice(0, 4);
        const second = account.slice(-4);

        return `${first}...${second} LOGOUT`;
    }

    const dispatch = useDispatch();

    const onConnect = (disconnect?: boolean) => {
        if (disconnect) {
            dispatch(disconnectWallet.request());
        } else {
            dispatch(connectWallet.request());
        }
        handleClose();
    }

    return (
        <NavigationContainer enabled={enabled}>
            <CloseNav enabled={enabled} onClick={handleClose}>X</CloseNav>
            <NavBar>
                <UnorderedList>
                    <a href='/'>
                        <ListItem message='Coming Soon'
                            enabled={enabled}
                        >
                            Lottery
                            <span></span>
                        </ListItem>
                    </a>
                    <a href='https://t.me/ZillaInuPortal'
                        target='_blank'
                    >
                        <ListItem
                         enabled={enabled}
                         >
                            Telegram
                        </ListItem>
                    </a>
                    <a href='/faq'>
                        <ListItem
                            enabled={enabled}
                        >
                            FAQ
                        </ListItem>
                    </a>
                    <ListItem
                        enabled={enabled}
                        onClick={() => onConnect(!!address)}
                    >
                        {!address ? 'Connect Wallet' : getAccountString(address)}
                    </ListItem>
                </UnorderedList>
            </NavBar>
        </NavigationContainer >
    )
}

export default Navigation;