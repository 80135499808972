import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'typesafe-actions';

import { connectWallet, disconnectWallet } from '../../store/wallet/actions';

interface HeaderContainerProps {
    hasScrolled: boolean;
    stickyHeader: boolean;
}

const HeaderContainer = styled.div<HeaderContainerProps>`
    padding: 1rem;
    height: 100%;
    max-height: ${({ hasScrolled, stickyHeader, theme }) => `calc(${theme.layout.headerHeight} - ${stickyHeader ? '3rem' : hasScrolled ? '3rem' : '0rem'})`};
    background-color: ${({ hasScrolled, stickyHeader, theme }) => stickyHeader ? theme.colors.tonyDarkBlue : !hasScrolled ? theme.colors.offWhite : theme.colors.tonyDarkBlue};
    color: #fff;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    ${({ hasScrolled, stickyHeader }) => (stickyHeader || hasScrolled) && `
        box-shadow: 0 0 15px rgba(0,0,0,.5);
    `}
    transition: all 0.45s ${({ theme }) => theme.transitions.main};
`;

const ContentContainer = styled.div`
    ${({ theme }) => theme.layout.desktopWidth};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 1rem;
`;

const Title = styled.h1`
    margin: 0;
    cursor: pointer;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

interface ButtonProps {
    mainButton?: boolean;
    message?: string;
}

const NavButton = styled.span<ButtonProps>`
    margin: 0 0.25rem;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    font-size: 1.1rem;
    font-weight: 700;
    cursor: pointer;
    & >span {
        font-size: 0.7rem;
        font-variation-settings: 'wght' 900;
        border-radius: 0.75rem;
        display: flex;
        align-items: center;
        z-index: 99;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.brandColor1};
        color: ${({ theme }) => theme.colors.uiColor2} !important;
        bottom: 1.5rem;
        opacity: 0;
        color: white;
        text-decoration: none;
        white-space: nowrap;
        transition: all 0.15s ${({ theme }) => theme.transitions.main};
        &:after {
            content: '${({ message }) => message}';
        }
        pointer-events: none;
        text-transform: uppercase;
        padding: 0.5rem 0.75rem;
        top: 5rem;
    }

    &:hover {
        & >span {
            opacity: 1;
            transition: all 0.15s ${({ theme }) => theme.transitions.main};
            transition-delay: 0;
        }
    }

    ${({ mainButton }) => mainButton && `
        border: 2px solid #ffffff95;
    `}

    &:hover {
        background-color: #ffffff40;
    }

    &:active {
        transform: translateY(2px);
    }

    ${({ theme }) => theme.media.small} {
        display: none;
    }

    transition: all 0.2s ${({ theme }) => theme.transitions.main};
`;

interface OllaProps {
    enabled: boolean;
}

const Hamburger = styled.div<OllaProps>`
    display: flex;
    visibility:  ${({ enabled }) => enabled ? 'hidden' : 'visible'};
    margin-right: 1rem;
    font-size: 2.0rem;
    font-weight: 500;
    cursor: pointer;

    ${({ theme }) => theme.media.smallUp} {
        display: none;
    }
`;

const Logo = styled.img`
    height: 6rem;
`;

const Hamburgerpng = styled.img`
    height: 1.65rem;
    width: 2.1rem;
`;

interface Props {
    enabled: boolean;
    handleNavigation: () => void;
    hasScrolled: boolean;
    stickyHeader: boolean;
}

export const Header: React.FC<Props> = ({ enabled, hasScrolled, stickyHeader, handleNavigation }) => {

    const address = useSelector((state: RootState) => state.wallet.account);

    const getAccountString = (account: string) => {
        const first = account.slice(0, 4);
        const second = account.slice(-4);

        return `${first}...${second} LOGOUT`;
    }

    const dispatch = useDispatch();

    const onConnect = (disconnect?: boolean) => {
        if (disconnect) {
            dispatch(disconnectWallet.request());
        } else {
            dispatch(connectWallet.request());
            document.body.style.overflowY = 'scroll';
        }
    }

    return (
        <HeaderContainer stickyHeader={stickyHeader} hasScrolled={hasScrolled}>
            <ContentContainer>
                <a href='/'>
                    <TitleContainer>
                        {/* <Logo src='/images/logo.svg' /> */}
                        <Title>Zilla Inu</Title>
                    </TitleContainer>
                </a>
                <ButtonContainer>
                    <a href='/'>
                        <NavButton message='Coming Soon'>
                            Lottery
                            <span></span>
                        </NavButton>
                    </a>
                    <a href='https://t.me/ZillaInuPortal'
                        target='_blank'
                    >
                        <NavButton>
                            Telegram
                        </NavButton>
                    </a>
                    <a href='/faq'>
                        <NavButton>
                            FAQ
                        </NavButton>
                    </a>

                    <NavButton
                        mainButton
                        onClick={() => onConnect(!!address)}
                    >
                        {!address ? 'Connect Wallet' : getAccountString(address)}
                    </NavButton>
                    <Hamburger onClick={handleNavigation} enabled={enabled}>
                        <Hamburgerpng src='/hamburger.png' />
                    </Hamburger>
                </ButtonContainer>
            </ContentContainer>
        </HeaderContainer>
    )
}

export default Header;