import { createGlobalStyle } from 'styled-components';
import { Theme } from './theme';

export interface ThemeWrapper {
    theme: Theme;
}

// Defining global styles which are injected in _app.tsx
export const GlobalStyle = createGlobalStyle<ThemeWrapper>`

    * {
        box-sizing: border-box;
    }

    html {
        scroll-behavior: smooth;
        font-size: calc(6px + 1.5vmin);
        font-weight: 500;
        ${({ theme }) => theme.media.smallUp} {
            font-size: 16px;
        }
    }
    
    body {
        position: relative;
        overflow-x: hidden;
        margin: 0;
        padding: 0;
        font-family: ${({ theme }) => theme.fonts.main};
        color: white;
    }

    .pancake {
        width: 18px;
        height: 18px;
    }

    button {
        font-size: inherit;
        font-family: inherit;
        border: 0;
        padding: 0;
    }

    a, a: visited, a: hover, a: focus {
        text-decoration: none;
        color: inherit;
    }
`