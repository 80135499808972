import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { RootAction, RootState, Services, isActionOf } from 'typesafe-actions';
import * as walletActions from './actions';

export const connectWalletEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, { api }) =>
        action$.pipe(
            filter(isActionOf(walletActions.connectWallet.request)),
            switchMap((action) =>
                from(api.wallet.connectWallet()).pipe(
                    map(walletActions.connectWallet.success),
                    catchError((message: string) => of(walletActions.connectWallet.failure(message)))
                )
            )
        );


export const disconnectWalletEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, { api }) =>
        action$.pipe(
            filter(isActionOf(walletActions.disconnectWallet.request)),
            switchMap((action) =>
                from(api.wallet.disconnectWallet()).pipe(
                    map(walletActions.disconnectWallet.success),
                    catchError((message: string) => of(walletActions.disconnectWallet.failure(message)))
                )
            )
        );


export const signOwnershipEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, { api }) =>
        action$.pipe(
            filter(isActionOf(walletActions.signOwnership.request)),
            switchMap((action) =>
                from(api.wallet.signOwnership(action.payload)).pipe(
                    map(walletActions.signOwnership.success),
                    catchError((message: string) => of(walletActions.signOwnership.failure(message)))
                )
            )
        );