import * as React from 'react';
import styled from 'styled-components';

const BackgroundColor = styled.div`
    background-color: white;
    width: 100%;
    height: 100%;
`;

const InfoContainer = styled.div`
    width: 100%;
    flex: 1;
    padding: 13rem 0;
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "Image Content";
    gap: 3rem;
    ${({ theme }) => theme.layout.desktopWidth};
    ${({ theme }) => theme.media.small} {
        grid-template-columns: 1fr;
        grid-template-areas:
            "Content"
            "Image";
        gap: 0rem;
    }
`;

const InfoOuterContainer = styled.div`
    width: 100%;
    padding-top: 3rem;
    background: url("/images/wave-bottom.svg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
`;

const BottomWave = styled.img.attrs({
    src: '/images/wave-top.svg'
})`
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
`;

const InfoMessage = styled.div`
    grid-area: Content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    ${({ theme }) => theme.media.small} {
        align-items: center;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.tonyDarkBlue};
    font-size: 1.5rem;
    padding: 2rem;
    ${({ theme }) => theme.media.small} {
        align-items: center;
        padding: 3rem;
    }
    & h4 {
        margin: 0;
        font-weight: 500;
    }
`;

const LogoContainer = styled.div`
    grid-area: Image;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ theme }) => theme.media.small} {
        align-items: flex-end;
    }
`;

const WonderingTony = styled.img.attrs({
    src: '/images/zilla-spinning.gif'
})`
    width: 70%;
    ${({ theme }) => theme.media.small} {
        width: 70%;
    }
`;

const Info: React.FunctionComponent = () => {
    return (
        <BackgroundColor id='the-problem'>
            <InfoOuterContainer>
                <InfoContainer>
                    <LogoContainer>
                        <WonderingTony />
                    </LogoContainer>

                    <InfoMessage>
                        <TitleContainer>
                            <h1>Where we excel</h1>
                            <h4>Rome was not built in one day and neither was Shiba Inu. Tokens that launch with great hype and 100x within a day do not have a successful launch. Having slower, organic growth in the first days and weeks is key to sustained success. </h4>
                        </TitleContainer>
                    </InfoMessage>
                </InfoContainer>
                <BottomWave />
            </InfoOuterContainer>
        </BackgroundColor>
    )
}

export default Info;