import * as React from 'react';
import styled from 'styled-components';

const HeroContainer = styled.div`
    ${({ theme }) => theme.layout.desktopWidth};
    width: 100%;
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "Content Image";
    gap: 3rem;

    ${({ theme }) => theme.media.small} {
        grid-template-columns: 1fr;
        grid-template-areas:
            "Content"
            "Image";
        gap: 0rem;
    }
`;

const HeroMessage = styled.div`
    grid-area: Content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    ${({ theme }) => theme.media.small} {
        align-items: flex-start;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 1.5rem;
    padding: 2rem;

    ${({ theme }) => theme.media.small} {
        padding: 3rem;
    }
    & h1 {
        margin-bottom: 0.5rem;
    }
    & h3 {
        margin: 0;
        margin-bottom: 0.2rem;
    }
`;

const LogoContainer = styled.div`
    grid-area: Image;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Logo = styled.img.attrs({
    src: '/images/logo.png'
})`
    width: 30rem;
`;

const ButtonsContainer = styled.div`
    grid-area: Buttons;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    padding-top: 2rem;

    ${({ theme }) => theme.media.small} {
        justify-content: center;
    }
`;

const NavButton = styled.span`
    margin: 0 0.25rem;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;
    border: 2px solid #ffffff95;

    &:hover {
        background-color: #ffffff40;
    }

    &:active {
        transform: translateY(2px);
    }

    transition: all 0.2s ${({ theme }) => theme.transitions.main};
`;

const AddressContainer = styled.span`
    text-decoration: underline;
    font-size: 1.2rem;
    font-weight: 300;
    cursor: pointer;
    margin-top: 0.2rem;
`;

const Hero: React.FunctionComponent = () => {

    const copyAddress = () => {
        navigator.clipboard.writeText('0xE26F748DDDFC8347B80a4cCAad197841837fB427');
    }

    return (
        <HeroContainer>
            <HeroMessage>
                <TitleContainer>
                    <h1>Meet Zilla Inu.</h1>
                    <h3>The paw-some way to win big!</h3>
                    {/* <AddressContainer onClick={copyAddress}>0xE26F748DDDFC8347B80a4cCAad197841837fB427</AddressContainer> */}
                    <ButtonsContainer>
                        <a
                            href='https://app.uniswap.org/#/swap?outputCurrency=0x32632548ea342203fC647A1c7677148Ed6C2ed08'
                            target='_blank'
                        >
                            <NavButton>
                                Buy token
                            </NavButton>
                        </a>
                        <a href='/#roadmap'>
                            <NavButton>
                                Roadmap
                            </NavButton>
                        </a>
                    </ButtonsContainer>
                </TitleContainer>
            </HeroMessage>
            <LogoContainer>
                <Logo />
            </LogoContainer>
        </HeroContainer>
    )
}

export default Hero;