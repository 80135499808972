import { createReducer } from 'typesafe-actions';
import { initialState } from './state';
import * as walletActions from './actions';

export default createReducer(initialState)
    .handleAction(
        walletActions.setWallet,
        (state, action) => {
            return {
                ...state,
                account: action.payload,
                signedOwnership: undefined,
            };
        }
    )
    .handleAction(
        walletActions.signOwnership.request,
        (state) => ({
            ...state,
            signingPending: true,
        })
    )
    .handleAction(
        walletActions.signOwnership.success,
        (state, action) => ({
            ...state,
            signedOwnership: action.payload,
            signingPending: false,
        })
    )
    .handleAction(
        walletActions.signOwnership.failure,
        (state) => ({
            ...state,
            signingPending: false,
        })
    );