import React from 'react';
import styled from 'styled-components';

import DropdownArrowIcon from './ui/icons/drowdown-arrow';
import ShowMore from './ui/animated/show-more';

const QuestionContainer = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.offWhite};
    border-radius: 3rem;
    margin: 0.5rem;
    padding: 1rem;
    //box-shadow: 0 0 5px rgba(0,0,0,.2);
    cursor: pointer;
`;

export const NoSelectDiv = styled.div`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const TitleContainer = styled(NoSelectDiv)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    gap: 1rem;
    padding: 1rem;
    font-size: 1.65rem;
`;

const AlignLeftContainer = styled(NoSelectDiv)`
    display: flex;
    justify-content: left;
`;

interface ReadMoreProps {
    active: boolean;
}

const ReadMoreContainer = styled(ShowMore)<ReadMoreProps>`
    padding: 0 1rem;
    & > * {
        margin: 0;
        margin-top: 0.5rem;
    }
`;

const Icon = styled(NoSelectDiv)``;

const Arrow = styled(DropdownArrowIcon)<ReadMoreProps>`
    margin-left: 1rem;
    transform-origin: bottom-right;
    transform: rotate(${({ active }) => (active ? '180deg' : '0deg')});
    transition: transform 0.3s ${({ theme }) => theme.transitions.main};
`;

interface Props {
    children?: React.ReactNode;
    className?: string;
    ref?: React.Ref<HTMLDivElement>;
    title: string;
}

export const Question: React.FC<Props> = ({
    children,
    className,
    ref,
    title,
}) => {
    const [readMore, setReadMore] = React.useState(false);

    const handleClick = () => {
        setReadMore(!readMore);
    };

    return (
        <QuestionContainer onClick={handleClick} className={className} ref={ref}>
            <TitleContainer>
                <AlignLeftContainer>
                    <span>{title}</span>
                </AlignLeftContainer>
                <Arrow height={30} width={30} active={readMore} fill={'white'} />
            </TitleContainer>
            <ReadMoreContainer active={readMore} calculate>{children}</ReadMoreContainer>
        </QuestionContainer>
    );
};

export default React.forwardRef(
    (props: Props, ref: React.Ref<HTMLDivElement>) => (
        <Question {...props} ref={ref as any} />
    )
);
